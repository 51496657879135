import moment from "moment";
import React, { useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { setBookingInfo } from "../slices/bookingSlice";
import celebration from "../assets/successgif.gif";
import './modalContent.css';

export default function PaymentForm() {
  const userInfo = useSelector((state) => state.userInfo.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  // const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    startDate: userInfo && userInfo.length > 0 ? moment(userInfo[0].dates.startDate).format("YYYY-MM-DD") : "",
    endDate: userInfo && userInfo.length > 0 ? moment(userInfo[0].dates.endDate).format("YYYY-MM-DD") : "",
    adult: userInfo && userInfo.length > 0 ? userInfo[0].adults : "",
    children: userInfo && userInfo.length > 0 ? userInfo[0].children : "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setLoading(true);
    axios.post(`https://www.test.rapidbrolly.co.uk/api/index.php`, formData)
      .then(response => {
        console.log(response.data);
        setShowModal(true);
        dispatch(setBookingInfo(formData));
        // setLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error);
        // setLoading(false);
      });
  };

  const handleContinue = () => {
    setShowModal(false);
    // setLoading(true);

    navigate("/booking/reservation/guestinfo");

    axios.post( `https://www.test.rapidbrolly.co.uk/api/after_payment_send_email.php`, formData)
      .then(response => {
        console.log(response.data);
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          startDate: "",
          endDate: "",
          adult: "",
          children: ""
        });
        // setLoading(false);
        // navigate("/booking/reservation/guestinfo");
      })
      .catch(error => {
        console.error('There was an error!', error);
        // setLoading(false);
      });
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-header text-center">
          <h2>Payment Form <FaRegClock /></h2>
          <p>
            Start Date: {userInfo.length > 0 && userInfo[0].dates.startDate
              ? moment(userInfo[0].dates.startDate).format("DD MMM YYYY")
              : "Not available"}
          </p>
          <p>
            End Date: {userInfo.length > 0 && userInfo[0].dates.endDate
              ? moment(userInfo[0].dates.endDate).format("DD MMM YYYY")
              : "Not available"}
          </p>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit} className="m-5">
            <div className="form-group mb-2">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block mt-4">
              Submit
            </button>
          </form>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <h2 className="text-center pt-4 box_content_heading">Payment successful!</h2>
        <Modal.Body>
          <div className="box_content px-2">
          <div  className="d-flex col-12 align-items-center justify-content-center"> <img src={celebration} style={{width: "130px", height: "130px"}} alt="Celebration"/></div>
            <p className="phra">
              Your payment has been successfully processed! To ensure a seamless
              experience with RUJ Sri Lanka, please provide guest information.{" "}
            </p>
            <div>
              <div className="d-flex justify-content-between list_content">
                <p>No.of nights</p>
                <div className="d-flex align-items-baseline">
                  <p>{moment(userInfo[0].dates.startDate).format("DD MMM")}{" - "}{moment(userInfo[0].dates.endDate).format("DD MMM")}</p>
                  <p className="ps-2 night_data"> {Math.round(
                    (userInfo[0].dates.endDate - userInfo[0].dates.startDate) / (1000 * 60 * 60 * 24)
                  )} night</p>
                </div>
              </div>
              <hr className="dotted_line"></hr>
              <div className="d-flex justify-content-between list_content">
                <p>Email address</p>
                <p>{formData.email}</p>
              </div>
              <hr className="dotted_line"></hr>
              <div className="d-flex justify-content-between list_content">
                <p>Phone number</p>
                <p>{formData.phoneNumber}</p> 
              </div>
              <hr className="dotted_line"></hr>
            </div>
            <p className="note">Note:</p>
            <p className="phra">
              If you accidentally close this window, don't worry! We'll send you
              an email notification to continue the process
            </p>
          </div>
        </Modal.Body>
        <div className="px-4 pt-3 pb-4">
          <button className="col-12 box_btn" onClick={handleContinue}>Continue</button>
        </div>
      </Modal>

      {/* <Modal show={loading} centered>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
           
          </Spinner>
          <p className="ml-3">Please wait...</p>
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
