import React, { useState, useEffect } from "react";
import NavBarGuestInfoPage from "../layouts/NavBarGuestInfoPage";
import bulb from '../assets/bulb.svg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiCalendar } from "react-icons/ci";
import { useSearchParams, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import celebration from "../assets/celebration.gif";
import './GuestInfoPage.css';

export default function GuestInfoThrowEmailSubmissionButton() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModalGuestInfo, setShowModalGuestInfo] = useState(false);
  const [formData, setFormData] = useState({
    guest1: '',
    guest2: '',
    guest3: '',
    guest4: '',
    guest5: '',
    guest6: '',
    acceptedPrivacyPolicy: false,
  });
  
  const [dobArray, setDobArray] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [validationMessage, setValidationMessage] = useState("");

  const encodedData = searchParams.get('data');
    let bookingInfo = {};

    if (encodedData) {
      const decodedData = JSON.parse(decodeURIComponent(atob(encodedData)));
      bookingInfo = {
        name: decodedData.name || "",
        email: decodedData.email || "",
        phoneNumber: decodedData.phoneNumber || "",
        startDate: decodedData.startDate || "",
        endDate: decodedData.endDate || "",
        adult: decodedData.adult || "",
        children: decodedData.children || "",
      };
    }

    if (bookingInfo.startDate && bookingInfo.endDate) {
        bookingInfo.no_night = Math.round((new Date(bookingInfo.endDate) - new Date(bookingInfo.startDate)) / (1000 * 60 * 60 * 24)) + 1;
      }

      const checkIfDataExists = async () => {
        try {
          const response = await axios.get(`https://www.test.rapidbrolly.co.uk/api/guest_info.php`, {
            params: {
              start_date: moment(bookingInfo.startDate).format("YYYY-MM-DD")
            }
          });
    
          if (response.data.status === 1 && response.data.data.length > 0) {
            return true; // Data exists
          }
          return false; // Data does not exist
        } catch (error) {
          console.error('Error checking date existence:', error);
          return false;
        }
      };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });

    if (checked) {
      setValidationMessage('');
    }
  };

  const handleDobChange = (date, index) => {
    const updatedDobArray = [...dobArray];
    updatedDobArray[index - 1] = date;
    setDobArray(updatedDobArray);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.acceptedPrivacyPolicy) {
      const dataExists = await checkIfDataExists();
      if (dataExists) {
        setShowModalGuestInfo(true);
        return;
      }

      const dataToSend = {
        start_date: moment(bookingInfo.startDate).format("YYYY-MM-DD"),
        end_date: moment(bookingInfo.endDate).format("YYYY-MM-DD"),
        guest1: formData.guest1,
        dob1: dobArray[0] ? moment(dobArray[0] ).format("YYYY-MM-DD") : null,
        guest2: formData.guest2,
        dob2: dobArray[1] ? moment(dobArray[1]).format("YYYY-MM-DD") : null,
        guest3: formData.guest3,
        dob3:dobArray[2] ? moment(dobArray[2]).format("YYYY-MM-DD") : null,
        guest4: formData.guest4,
        dob4: dobArray[3] ? moment(dobArray[3]).format("YYYY-MM-DD") : null,
        guest5: formData.guest5,
        dob5: dobArray[4] ? moment(dobArray[4]).format("YYYY-MM-DD") : null,
        guest6: formData.guest6,
        dob6: dobArray[5] ? moment(dobArray[5]).format("YYYY-MM-DD") : null,
      };

      axios.post(`https://www.test.rapidbrolly.co.uk/api/guest_info.php`, dataToSend)
        .then(response => {
          if (response.data.status === 1) {
            setShowModal(true);
          } else {
            setShowModalGuestInfo(true);
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });

      const UserInformation = {
        name: bookingInfo.name,
        email: bookingInfo.email,
        phoneNumber: bookingInfo.phoneNumber,
        startDate: bookingInfo.startDate,
        endDate: bookingInfo.endDate,
        adult: bookingInfo.adult,
        children: bookingInfo.children,
        no_night: bookingInfo.no_night,
      };

      axios.post(`https://www.test.rapidbrolly.co.uk/api/booking_finish_send_email.php`, UserInformation)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error('There was an error!', error);
        });

    } else {
      setValidationMessage('You must accept the privacy policy.');
    }
  };

  const handleContinue = () => {
    setShowModal(false);
    // setLoading(true);

    navigate("/");

 
  };

  const handleCloseModalGuestInfo = () => {
    setShowModalGuestInfo(false);
    // setLoading(true);
    navigate("/");
 
  };

    // Get current year and set minDate
    const minDate = moment().startOf("year").toDate();


  return (
    <div className="">
      <NavBarGuestInfoPage />
      <div className="py-5 guest_info_page_div_main">
        <div className="guest_info_main_div">
          <div className="d-flex justify-content-between align-items-center page_14_form_feild">
            <p className="mb-0">No. of nights</p>
            <div className="d-flex align-items-center justify-content-center page14_selected_date_range_text">
              <div className="d-flex align-items-baseline">
                <p className="m-0">{moment(bookingInfo.startDate).format("DD MMM")}{" - "}{moment(bookingInfo.endDate).format("DD MMM")}</p>
                <p className="ms-3 mb-0 night">{bookingInfo.no_night} nights</p>
              </div>
            </div>
          </div>
          <hr className="col-12 mb-4" />
          <div className="page_guest_info_heanding_div">
            <h2>Guest(s) info</h2>
            <p className="p1">We gather your data to facilitate a seamless check-in experience.</p>
            <div className='d-flex align-items-center'>
              <img src={bulb} className="img-fluid me-2" alt="Bulb" />
              <p className="mb-0 p2">Please fill in names as they appear on the ID used during check-in</p>
            </div>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <div className="d-block d-md-flex align-items-center justify-content-between guest_info_form_main_div col-12 py-4" key={index}>
                  <div className="col-12 col-md-6 me-4 mb-3 mb-md-0">
                    <label>Full name of guest {index} {index === 1 && <span> *</span>}</label>
                    <div className="d-flex align-items-center input_div">
                      <input
                        type="text"
                        className="form-control"
                        id={`guest${index}`}
                        name={`guest${index}`}
                        value={formData[`guest${index}`]}
                        onChange={handleChange}
                        required={index === 1}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label>Date of birth {index === 1 && <span> *</span>}</label>
                    <div className="d-flex position-relative input_div date-picker-container">
                    <DatePicker
                        selected={dobArray[index - 1]}
                        onChange={(date) => handleDobChange(date, index)}
                        placeholderText="MM-DD-YYYY"
                        dateFormat="MM-dd-yyyy"
                        className="form-control date-picker-input"
                        showYearDropdown
                        yearDropdownItemNumber={120}
                        maxDate={minDate}
                        
                      />
                      <CiCalendar className="calender_icon position-absolute end-0 me-1" />
                    </div>
                  </div>
                </div>
              ))}
              <div className="mb-3 ms-0 ms-md-1  mt-4 ">
                <div className="d-flex ">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="acceptedPrivacyPolicy"
                    name="acceptedPrivacyPolicy"
                    checked={formData.acceptedPrivacyPolicy}
                    onChange={handleChange}
                  />
                  <div className="d-flex align-items-center justify-content-center">
                    <label className="form-check-label-page14 ms-1" htmlFor="acceptedPrivacyPolicy">
                      I’m happy to get exclusive offers and updates from RUJ Sri Lanka.
                      I understand I can unsubscribe at any time
                    </label>
                  </div>
                </div>
                {validationMessage && (
                  <div className="alert alert-danger" role="alert">
                    {validationMessage}
                  </div>
                )}
                <button
                  type="submit"
                  className="Page14_form_btn col-12"
                >
                  Complete reservation
                </button>
              </div>
              <div className="page14_last_phra">
                <p>By continuing, you acknowledge and agree to <span>RUJ’s Terms of Use and Privacy Statement</span></p>
              </div>
            </form>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
        <h2 className="text-center pt-4 box_content_heading">Congratulations!</h2>
        <Modal.Body>
          <div className="box_content px-2">
         <div  className="d-flex col-12 align-items-center justify-content-center"> <img src={celebration} style={{width: "190px", height: "190px"}} alt="Celebration"/></div>

            <p className="phra">
            Your guest information has been successfully submitted. We sincerely appreciate your valuable time spent with us{" "}
            </p>
            <div>
              <div className="d-flex justify-content-between list_content">
                <p>No.of nights</p>
                <div className="d-flex align-items-baseline">
                  <p>{moment(bookingInfo.startDate).format("DD MMM")}{" - "}{moment(bookingInfo.endDate).format("DD MMM")}</p>
                  <p className="ps-2 night_data"> {bookingInfo.no_night} night</p>
                </div>
              </div>
              <hr className="dotted_line"></hr>
              <div className="d-flex justify-content-between list_content">
                <p>Email address</p>
                <p>{bookingInfo.email}</p>
              </div>
              <hr className="dotted_line"></hr>
              <div className="d-flex justify-content-between list_content">
                <p>Phone number</p>
                <p>{bookingInfo.phoneNumber}</p> 
              </div>
              <hr className="dotted_line"></hr>

              <div className="d-flex justify-content-between list_content">
                <p>No.of guests</p>
               <div className="d-flex align-items-baseline"> <p >{bookingInfo.adult}</p><p>{" "}</p> <p>adults</p> <p className="ms-1 me-1">{","}</p><p>{bookingInfo.children}</p> <p>{" "}</p><p>children</p></div>
              </div>
              <hr className="dotted_line"></hr>
            </div>
            <p className="note">Note:</p>
            <p className="phra">
            An email confirmation with the next steps 
            </p>
          </div>
        </Modal.Body>
        <div className="px-4 pt-3 pb-4">
          <button className="col-12 box_btn" onClick={handleContinue}>Back to Homepage</button>
        </div>
      </Modal>
          </div>
        </div>
      </div>

      <Modal show={showModalGuestInfo} onHide={() => setShowModalGuestInfo(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Guest Information already store</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Thank you for choosing RUJ Srilanka. We noticed that you’ve already provided your guest information. If you need to make any updates or have any questions, please let us know.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalGuestInfo}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
